import { useCallback, useState } from "react";

export function useMountedNodeRef<T>() {
  const [ref, setRef] = useState<T>();
  const refHandler = useCallback((node: T) => {
    if (node !== null && node !== ref) {
      setRef(node);
    }
  }, []);
  return [ref, refHandler] as [undefined | T, (node: T) => void];
}
