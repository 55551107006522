import Ajv from "ajv";
import jsf, { Schema } from "json-schema-faker";
import { ParticleConfigState } from "../types/state";

import {
  augmentSchemaWithRefDefinitions,
  ObjectTypeSchemas,
  ParticleConfigSchema,
  SpikeConfigGroupSchema,
} from "../schema";
import {
  IdentifiedConfigGroup,
  ObjectDef,
  ObjectType,
  SpikeDef,
} from "../types";

// @ts-ignore
jsf.option("refDepthMin", 3);
// @ts-ignore
jsf.option("refDepthMax", 15);
jsf.option("requiredOnly", true);

const generateData = <T extends IdentifiedConfigGroup | ParticleConfigState>(
  schema: Schema
) =>
  jsf.generate(augmentSchemaWithRefDefinitions(schema) as any) as unknown as T;

const getObjectTypeschema = (objectType: ObjectType) => {
  const schema = ObjectTypeSchemas.get(objectType);
  if (!schema) {
    throw new Error(
      `Couldn't mutate to new schema, no schema found for ${objectType}!`
    );
  }
  return schema;
};

const createRandomizedObjectDef = (objectType: ObjectType) =>
  generateData<ObjectDef>(getObjectTypeschema(objectType) as Schema);

export const createRandomizedParticleConfig = () =>
  generateData<ParticleConfigState>(ParticleConfigSchema as Schema);

export const createRandomizedSpikeDef = () =>
  generateData<SpikeDef>(SpikeConfigGroupSchema as Schema);

export const mutateObjectDefToOtherType = (
  def: ObjectDef,
  objectType: ObjectType
): ObjectDef => {
  if (def.type === objectType) {
    return def;
  }
  const keptValues = { ...def };
  const ajv = new Ajv({ removeAdditional: "all", allErrors: true });
  const schema = getObjectTypeschema(objectType);
  const validate = ajv.compile<ObjectDef>(schema);
  const newRandomDef = createRandomizedObjectDef(objectType);

  validate(keptValues);

  return {
    ...newRandomDef,
    ...keptValues,
    // for some reason ajv does not scrub this
    type: objectType,
  } as ObjectDef;
};
