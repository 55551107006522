import { LevaInputProps } from "leva/dist/declarations/src/types";

// Particle config
export enum ObjectType {
  Icosahedron = "Icosahedron",
  Sphere = "Sphere",
}

export enum ConfigGroupType {
  Environment = "Environment",
  Spike = "Spike",
  Capsid = "Capsid",
}

export type IdentifiedConfigGroup = {
  uuid: string;
  type: ObjectType | ConfigGroupType;
};

export interface IObjectDef extends IdentifiedConfigGroup {
  type: ObjectType;
}

export type IcosahedronComplexity = 0 | 1 | 2 | 3;
export interface IcosahedronDef extends IObjectDef {
  type: ObjectType.Icosahedron;
  color: string;
  complexity: IcosahedronComplexity;
  size: number;
}

export interface SphereDef extends IObjectDef {
  type: ObjectType.Sphere;
  color: string;
  complexityX: number;
  complexityY: number;
  size: number;
}

export type ObjectDef = SphereDef | IcosahedronDef;

// Config types
export type SpikeDef = IdentifiedConfigGroup & {
  type: ConfigGroupType.Spike;
  distance: number;
  weight: number;
  object: ObjectDef;
};

export type EnvironmentDef = IdentifiedConfigGroup & {
  type: ConfigGroupType.Environment;
  background: string;
};

export type CapsidDef = IdentifiedConfigGroup & {
  type: ConfigGroupType.Capsid;
  object: ObjectDef;
};

export type RootDef = CapsidDef | EnvironmentDef | SpikeDef;

export type Def = RootDef | ObjectDef;

export type ParticleConfigState = {
  environment: EnvironmentDef | null;
  capsid: CapsidDef | null;
  spikes: SpikeDef[] | null;
};

// Rendering config
export type RenderingOptionsState = {
  wireframe: boolean;
  shadows: boolean;
  postprocessing: boolean;
};

// Ui state
export type SelectionInfo = {
  uuid?: string;
  path?: string;
};

export type UiState = {
  hoveringSelection: SelectionInfo | null;
  editingSelection: SelectionInfo | null;
  controlsConfig: ObjectDefWithHandlers | null;
};

// controls
export type ObjectDefWithHandlers = Partial<{
  [x in keyof ObjectDef]: Partial<LevaInputProps<ObjectDef[x]>>;
}>;
