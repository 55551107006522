import { ConfigGroupType, ObjectType } from "../types";

export const ColorSchema = {
  type: "string",
  pattern: "^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$",
};

export const UuidSchema = {
  type: "string",
  pattern:
    "^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$",
};

export const ConfigTypeSchema = {
  enum: ["Capsid", "Environment", "Icosahedron", "Sphere", "Spike"],
  type: "string",
};

export const ObjectTypeSchema = {
  enum: ["Icosahedron", "Spike"],
  type: "string",
};

export const IcosahedronConfigGroupSchema = {
  $id: "IcosahedronConfigGroupSchema",
  type: "object",
  additionalProperties: false,
  properties: {
    color: ColorSchema,
    uuid: UuidSchema,
    complexity: {
      type: "integer",
      minimum: 0,
      maximum: 3,
    },
    size: {
      type: "number",
      minimum: 0.1,
      maximum: 2,
      multipleOf: 0.01,
    },
    type: {
      enum: [ObjectType.Icosahedron],
      type: "string",
    },
  },
  required: ["uuid", "type", "color", "complexity", "size"],
};

export const SphereConfigGroupSchema = {
  $id: "SphereConfigGroupSchema",
  type: "object",
  additionalProperties: false,
  properties: {
    uuid: UuidSchema,
    color: ColorSchema,
    size: {
      type: "number",
      minimum: 0.1,
      maximum: 2,
      multipleOf: 0.01,
    },
    complexityX: {
      type: "integer",
      minimum: 3,
      maximum: 48,
    },
    complexityY: {
      type: "integer",
      minimum: 2,
      maximum: 32,
    },
    type: {
      enum: [ObjectType.Sphere],
      type: "string",
    },
  },
  required: ["uuid", "type", "size", "color", "complexityX", "complexityY"],
};

export type ObjectDefSchema =
  | typeof IcosahedronConfigGroupSchema
  | typeof SphereConfigGroupSchema;

export const ObjectTypeSchemas = new Map<ObjectType, ObjectDefSchema>([
  [ObjectType.Icosahedron, IcosahedronConfigGroupSchema],
  [ObjectType.Sphere, SphereConfigGroupSchema],
]);

export const ObjectConfigGroupSchema = {
  $id: "ObjectConfigGroupSchema",
  type: "object",
  oneOf: [IcosahedronConfigGroupSchema, SphereConfigGroupSchema],
};

export const EnvironmentConfigGroupSchema = {
  $id: "EnvironmentConfigGroupSchema",
  type: "object",
  properties: {
    uuid: UuidSchema,
    background: ColorSchema,
    type: {
      enum: [ConfigGroupType.Environment],
      type: "string",
    },
  },
  required: ["uuid", "type", "background"],
};

export const CapsidConfigGroupSchema = {
  $id: "CapsidConfigGroupSchema",
  type: "object",
  properties: {
    uuid: UuidSchema,
    object: {
      $ref: "#/definitions/ObjectConfigGroupSchema",
    },
    type: {
      enum: [ConfigGroupType.Capsid],
      type: "string",
    },
  },
  required: ["uuid", "type", "object"],
};

export const SpikeConfigGroupSchema = {
  $id: "SpikeConfigGroupSchema",
  type: "object",
  properties: {
    uuid: UuidSchema,
    weight: {
      type: "integer",
      minimum: 0,
    },
    distance: {
      type: "number",
      minimum: 0.1,
      maximum: 3,
      multipleOf: 0.1,
    },
    object: {
      $ref: "#/definitions/ObjectConfigGroupSchema",
    },
    type: {
      enum: [ConfigGroupType.Spike],
      type: "string",
    },
  },
  required: ["uuid", "type", "weight", "object", "distance"],
};

export const ParticleConfigSchema = {
  $id: "ParticleConfigSchema",
  type: "object",
  properties: {
    environment: {
      $ref: "#/definitions/EnvironmentConfigGroupSchema",
    },
    capsid: {
      $ref: "#/definitions/CapsidConfigGroupSchema",
    },
    spikes: {
      type: "array",
      items: {
        $ref: "#/definitions/SpikeConfigGroupSchema",
      },
      maxItems: 3,
    },
  },
  required: ["environment", "capsid", "spikes"],
};

export const ConfigSchemaRefDefinitions = {
  IcosahedronConfigGroupSchema: IcosahedronConfigGroupSchema,
  SphereConfigGroupSchema: SphereConfigGroupSchema,
  ObjectConfigGroupSchema: ObjectConfigGroupSchema,
  EnvironmentConfigGroupSchema: EnvironmentConfigGroupSchema,
  CapsidConfigGroupSchema: CapsidConfigGroupSchema,
  SpikeConfigGroupSchema: SpikeConfigGroupSchema,
  ParticleConfigSchema: ParticleConfigSchema,
};

export const augmentSchemaWithRefDefinitions = (schema: object) => ({
  ...schema,
  definitions: ConfigSchemaRefDefinitions,
});
