import { Leva, levaStore, useControls } from "leva";
import { LevaCustomTheme } from "leva/dist/declarations/src/styles";
import { Schema } from "leva/dist/declarations/src/types";
import React, { useEffect, useMemo } from "react";

import { getParticleConfig, useUiState } from "../stores";
import { getDefByUuid, makeControlDef } from "../utils";

const levaTheme: LevaCustomTheme = {
  borderWidths: {
    root: "0px",
    input: "0px",
    focus: "0px",
    hover: "0px",
    active: "0px",
    folder: "0px",
  },

  fontSizes: {
    root: "1rem",
    toolTip: "$root",
  },
  fonts: {
    sans: `ui-monospace, sans-serif`,
  },
  sizes: {
    rootWidth: "40ch",
    folderTitleHeight: "2.5em",
    numberInputMinWidth: "7ch",
  },
  space: {
    md: "0.75rem",
    sm: "0.66rem",
    xs: "0.33rem",
    colGap: "$md",
    rowGap: "$md",
  },
  colors: {
    elevation1: "rgba(var(--contrast-color-values), 1)", // bg color of the root panel (main title bar)
    elevation2: "$elevation1", // bg color of the rows (main panel color)
    elevation3: "rgba(var(--bg-color-values), 0.2)", // bg color of the inputs
    accent1: "rgba(var(--bg-color-values), 0.4)",
    accent2: "$accent1",
    accent3: "$accent1",
    highlight1: "var(--bg-color)",
    highlight2: "$highlight1",
    highlight3: "$highlight1",
    vivid1: "#ffcc00",
    folderWidgetColor: "$highlight2",
    folderTextColor: "$highlight3",
    toolTipBackground: "$highlight3",
    toolTipText: "$elevation2",
  },
};

const emptyObj = {};
export const Controls = () => {
  const [hoveringSelection] = useUiState.hoveringSelection();

  useEffect(() => {
    document.body.style.cursor = hoveringSelection ? "pointer" : "initial";
  }, [hoveringSelection]);

  const [editingSelection] = useUiState.editingSelection();
  const foundConfig = useMemo<false | [string, Schema]>(() => {
    if (!editingSelection?.uuid) {
      return false;
    }
    const [particleConfig] = getParticleConfig();
    const foundDef = getDefByUuid(particleConfig, editingSelection!.uuid);
    if (foundDef) {
      const controlDef = makeControlDef(foundDef, true);

      return controlDef!;
    }
    return false;
  }, [editingSelection]);
  const titleBarConf = useMemo(
    () => foundConfig && { title: foundConfig[0] },
    [foundConfig]
  );

  // Prevent leva from erroneously persisting settings from previous
  // selection.
  useEffect(() => {
    levaStore.dispose();
  }, [foundConfig]);

  useControls(foundConfig ? foundConfig[1] : emptyObj, [foundConfig]);

  return foundConfig ? (
    <Leva flat theme={levaTheme} hideCopyButton titleBar={titleBarConf} />
  ) : null;
};
