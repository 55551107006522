import React, { memo, useMemo } from "react";

import { useRenderingOptions } from "../stores";
import { IcosahedronDef, ObjectProps } from "../types";

const ObjectIcosahedron_ = ({
  def,
  onUpdateGeo,
}: ObjectProps<IcosahedronDef>) => {
  const [wireframe] = useRenderingOptions.wireframe();
  const geoArgs = useMemo<[number, number]>(
    () => [def.size, def.complexity],
    [def.size, def.complexity]
  );
  return (
    <>
      <icosahedronGeometry args={geoArgs} onUpdate={onUpdateGeo} />
      <meshPhysicalMaterial color={def.color} wireframe={wireframe} />
    </>
  );
};

export const ObjectIcosahedron = memo(ObjectIcosahedron_);
