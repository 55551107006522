import { omit } from "lodash-es";
import shortUUID from "short-uuid";

import { getParticleConfig, getUiState } from "../stores";
import { Def, ObjectDef, ObjectType, ParticleConfigState } from "../types";
import {
  createRandomizedParticleConfig,
  createRandomizedSpikeDef,
  mutateObjectDefToOtherType,
} from "./schema";

export type PropPath = (string | number)[];
export type ControlConfig = Def;
export type ConfigDescriptor = [PropPath, ControlConfig];

const translator = shortUUID(); // Defaults to flickrBase58
export const generateSpikeName = (uuid: string) => translator.fromUUID(uuid);

export const getDefByUuid = (
  obj: object,
  uuid: string,
  path = [] as PropPath
): undefined | ConfigDescriptor => {
  let value: undefined | ConfigDescriptor;

  (Object.keys(obj) as Array<keyof typeof obj>).some((k) => {
    // string properties
    if (k === "uuid" && obj[k] === uuid) {
      value = [path, obj as ControlConfig];
      return true;
    }

    // object properties
    if (obj[k] && typeof obj[k] === "object" && !Array.isArray(obj[k])) {
      const found = getDefByUuid(obj[k], uuid, [...path, k]);
      if (found) {
        value = found;
      }
      return typeof value !== "undefined" ? value : false;
    }

    // array properties
    if (obj[k] && Array.isArray(obj[k])) {
      (obj[k] as Array<unknown>).some((v, i) => {
        if (typeof v === "object" && v != null) {
          const found = getDefByUuid(v, uuid, [...path, k, i]);
          if (found) {
            value = found;
            return true;
          }
        }
        return false;
      });
      return typeof value !== "undefined" ? value : false;
    }
  });

  return value;
};

export const handleCreateNewSpike = async (focus = true) => {
  const newSpike = createRandomizedSpikeDef();
  const [spikes, setSpikes] = getParticleConfig.spikes();
  setSpikes([...(spikes || []), newSpike]);

  if (focus) {
    const [, setEditingSelection] = getUiState.editingSelection();
    setEditingSelection({
      uuid: newSpike.uuid,
    });
  }
};

export const handleChangeDef = (path: PropPath, v: unknown) => {
  const helper = path.reduce(
    (prevHelper, pathItem) => (prevHelper as object)[pathItem as keyof object],
    getParticleConfig
  );
  const [oldValue, setter] = helper();
  if (v !== oldValue) {
    setter(v as never);
  }
};

export const handleChangeObjectType = (
  path: PropPath,
  newType: ObjectType
): ObjectDef => {
  const helper = path.reduce(
    (prevHelper, pathItem) => (prevHelper as object)[pathItem as keyof object],
    getParticleConfig
  );
  const [oldDef, setter] = helper() as unknown as [
    ObjectDef,
    (v: ObjectDef) => void
  ];

  const mutatedDef = mutateObjectDefToOtherType(oldDef, newType);
  if (mutatedDef !== (oldDef as never)) {
    setter(mutatedDef as never);
  }
  return mutatedDef;
};

export const handleRandomizeParticleConfig = async () => {
  const newConfig = createRandomizedParticleConfig();
  const [, setParticleConfig] = getParticleConfig();
  setParticleConfig(newConfig);

  // allow this to run before ui state is initialised
  if (getUiState.editingSelection) {
    const [, setEditingSelection] = getUiState.editingSelection();
    setEditingSelection(null);
  }
};

export const handleDeleteDef = (path: PropPath, looseFocus = false) => {
  const lastKey = path[path.length - 1];
  const pathOfParentObject = path.slice(0, -1);
  const parentHelper = pathOfParentObject.reduce<
    () => [any, (v: (v: any) => void | any) => void]
  >(
    (prevHelper, pathItem) => (prevHelper as object)[pathItem as keyof object],
    getParticleConfig as any
  );
  const [parentObj, setter] = parentHelper();
  if (Array.isArray(parentObj)) {
    setter((oldParent) => (oldParent as any[]).filter((_, i) => i !== lastKey));
  } else if (typeof parentObj === "object") {
    setter((oldParent) => omit(oldParent, lastKey) as ParticleConfigState);
  } else {
    setter(() => null);
  }

  if (looseFocus) {
    const [, setEditingSelection] = getUiState.editingSelection();
    setEditingSelection(null);
  }
};
