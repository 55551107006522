import createStore from "teaful";

import { ParticleConfigState } from "../types";

const initialState: ParticleConfigState = {
  spikes: [],
  capsid: null,
  environment: null,
};

export const { useStore: useParticleConfig, getStore: getParticleConfig } =
  createStore(initialState);
