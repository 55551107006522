import React, { useCallback } from "react";
import { Vector3 } from "three";

import { useParticleConfig } from "../stores";
import { Object } from "./Object";

const origin = new Vector3(0, 0, 0);

type ParticleCapsidProps = {
  handleMeshRef?: (ref: THREE.Mesh) => void;
  handleGeoRef?: (ref: THREE.BufferGeometry) => void;
};
export const ParticleCapsid = ({
  handleMeshRef,
  handleGeoRef,
}: ParticleCapsidProps) => {
  const [capsidDef] = useParticleConfig.capsid();

  if (!capsidDef) {
    return null;
  }

  const handleUpdateGeo = useCallback(
    (geoRef: THREE.BufferGeometry) => {
      if (!geoRef) {
        return;
      }
      // Vertex normals are used for shading and are required to calculate the
      // normals for the spike positions in handleGeoRef.
      geoRef.computeVertexNormals();
      if (handleGeoRef) {
        handleGeoRef(geoRef);
      }
    },
    [handleGeoRef]
  );

  const handleUpdateMesh = useCallback(
    (meshRef: THREE.Mesh) => {
      if (!meshRef || !handleMeshRef) {
        return;
      }
      handleMeshRef(meshRef);
    },
    [handleMeshRef]
  );

  return (
    <Object
      configUuid={capsidDef.uuid}
      position={origin}
      def={capsidDef.object}
      onUpdateGeo={handleUpdateGeo}
      onUpdateMesh={handleUpdateMesh}
    />
  );
};
