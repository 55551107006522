import React, { useEffect } from "react";
import { Canvas } from "../components-three/Canvas";
import { css } from "@emotion/css";

import { Controls } from "./Controls";
import { Menu } from "./Menu";
import { handleRandomizeParticleConfig } from "../utils";

export const App = () => {
  useEffect(() => {
    handleRandomizeParticleConfig();
  }, []);
  return (
    <div
      className={css`
        height: 100%;
        font-family: ui-monospace, monospace;
        color: var(--contrast-color);
      `}
    >
      <header
        className={css`
          position: fixed;
          top: 1em;
          left: 1em;
          z-index: 9;
        `}
      >
        <h1
          className={css`
            margin: 0 0 0.2em 0;
            font-weight: normal;
            letter-spacing: 0.3em;
            font-size: 1em;
          `}
        >
          virion
        </h1>
        <p
          className={css`
            margin: 0;
            letter-spacing: 0.02em;
          `}
        >
          visual virus particle editor
        </p>
      </header>
      <Controls />
      <Menu />
      <Canvas
        className={css`
          display: flex;
          height: 100%;
          width: 100%;
        `}
      />
    </div>
  );
};
