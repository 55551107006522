import tinycolor from "tinycolor2";
import { useEffect, useState } from "react";

export const useCssVars = (
  backgroundColor: string | null
): [string | undefined] => {
  const [contrastColor, setContrastColor] = useState<string>();
  useEffect(() => {
    if (!backgroundColor) {
      return;
    }
    const bgColor = tinycolor(backgroundColor);
    const newColor = bgColor.complement();

    if (tinycolor.readability(bgColor, newColor) < 15) {
      if (bgColor.isLight()) {
        newColor.darken(50);
      } else {
        newColor.lighten(50);
      }
    }
    const newColorStr = newColor.toString();
    const { r: cr, g: cg, b: cb } = newColor.toRgb();
    const { r: br, g: bg, b: bb } = bgColor.toRgb();
    setContrastColor(newColorStr);
    document.body.style.setProperty("--contrast-color", newColorStr);
    document.body.style.setProperty(
      "--contrast-color-values",
      `${cr}, ${cg}, ${cb}`
    );
    document.body.style.setProperty("--bg-color-values", `${br}, ${bg}, ${bb}`);
    document.body.style.setProperty("--bg-color", backgroundColor);
  }, [backgroundColor]);
  return [contrastColor];
};
