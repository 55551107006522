import { animated, useSpring } from "@react-spring/three";
import { useFrame } from "@react-three/fiber";
import React, { useEffect, useRef, useState } from "react";

import { useMountedNodeRef } from "../hooks";
import { useParticleConfig } from "../stores";
import { ParticleCapsid } from "./ParticleCapsid";
import { ParticleSpikes } from "./ParticleSpikes";

export const Particle = () => {
  const particleRef = useRef<THREE.Group>();

  // Set up state for the hovered and active state

  const [scaleSpring] = useSpring(() => ({ scale: 1 }));
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame(() => {
    if (typeof particleRef?.current !== "undefined") {
      const speed = 0.005;
      particleRef.current.rotation.x += speed;
      particleRef.current.rotation.z -= speed;
    }
  });

  // When the capsid geometry is updated, find the vertices positions and use
  // them to render the spikes.
  const [vertices, setVertices] = useState<
    null | THREE.BufferAttribute | THREE.InterleavedBufferAttribute
  >(null);
  const [meshRef, handleMeshRef] = useMountedNodeRef<THREE.Mesh>();
  const [geoRef, handleGeoRef] = useMountedNodeRef<THREE.BufferGeometry>();

  const [spikes] = useParticleConfig.spikes();

  useEffect(() => {
    if (!meshRef || !geoRef) {
      setVertices(null);
      return;
    }
    const positionsBuffer = geoRef.getAttribute("position");

    setVertices(positionsBuffer.applyMatrix4(meshRef.matrix));
  }, [geoRef, meshRef, spikes && spikes.length]);

  return (
    <animated.group name="virion" ref={particleRef} {...scaleSpring}>
      {vertices && <ParticleSpikes vertices={vertices} />}
      <ParticleCapsid
        handleMeshRef={handleMeshRef}
        handleGeoRef={handleGeoRef}
      />
    </animated.group>
  );
};
