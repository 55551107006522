import { get } from "lodash-es";
import createStore from "teaful";
import { getParticleConfig } from "./particleConfig";
import { ParticleConfigState, IdentifiedConfigGroup, UiState } from "../types";
import { getDefByUuid } from "../utils";

const initialState: UiState = {
  editingSelection: null,
  hoveringSelection: null,
  controlsConfig: null,
};

type AfterUpdateCbParams = {
  store: UiState;
  prevStore: UiState;
};

export const { useStore: useUiState, getStore: getUiState } = createStore(
  initialState,
  onAfterUpdate
);

export const handleEditingSelectionPathUpdate = (path: string) => {
  const [, setEditingSelection] = getUiState.editingSelection();
  setEditingSelection({
    path,
  });
};

export const handleEditingSelectionUuidUpdate = (uuid: string) => {
  const [, setEditingSelection] = getUiState.editingSelection();
  setEditingSelection({
    uuid,
  });
};

export const handleHoveringSelectionUuidUpdate = (uuid: string) => {
  const [, setHoveringSelection] = getUiState.hoveringSelection();
  setHoveringSelection({
    uuid,
  });
};

export const handleHoveringSelectionPathUpdate = (path: string) => {
  const [, setHoveringSelection] = getUiState.hoveringSelection();
  setHoveringSelection({
    path,
  });
};

/**
 * If a new uuid is set and the current path is the same as last time
 */
const updatePathFromNewUuid = (
  selectionName: "editingSelection" | "hoveringSelection",
  particleConfig: ParticleConfigState,
  prevStore: UiState,
  store: UiState
) => {
  const selection = store[selectionName];
  const prevSelection = prevStore[selectionName];
  if (!selection || !selection.uuid) {
    return;
  }
  const hasNewuuid = !prevSelection || selection.uuid !== prevSelection.uuid;
  const isAlreadyUpdated =
    prevSelection?.path && selection.path === prevSelection?.path
      ? true
      : false;

  if (hasNewuuid && !isAlreadyUpdated) {
    const uuidSelectionConfig = getDefByUuid(particleConfig, selection.uuid);

    getUiState[selectionName]()[1]({
      ...store[selectionName],
      path: uuidSelectionConfig ? uuidSelectionConfig[0].join(".") : undefined,
    });
  }
  return store;
};

/**
 * Update the uuid from the optional path
 */
const updateUuidFromNewPath = (
  selectionName: "editingSelection" | "hoveringSelection",
  particleConfig: ParticleConfigState,
  prevStore: UiState,
  store: UiState
) => {
  const selection = store[selectionName];
  if (
    !selection ||
    !selection.path ||
    prevStore[selectionName]?.path === selection.path!
  ) {
    return;
  }
  const defByPathSelection: IdentifiedConfigGroup | undefined = get(
    particleConfig,
    selection.path
  );
  if (defByPathSelection) {
    const newDef = defByPathSelection;
    if (selection.uuid !== defByPathSelection.uuid) {
      getUiState[selectionName]()[1]({
        ...store[selectionName],
        uuid: newDef.uuid,
      });
    }
  }
  return store;
};

export const retriggerEditingSelection = () => {
  const [editingSelection, setEditingSelection] = getUiState.editingSelection();
  setEditingSelection(null);
  setEditingSelection(editingSelection ? { ...editingSelection } : null);
};

function onAfterUpdate({ store, prevStore }: AfterUpdateCbParams) {
  const [particleConfig] = getParticleConfig();

  updatePathFromNewUuid("editingSelection", particleConfig, prevStore, store);
  updateUuidFromNewPath("editingSelection", particleConfig, prevStore, store);
  updatePathFromNewUuid("hoveringSelection", particleConfig, prevStore, store);
  updateUuidFromNewPath("hoveringSelection", particleConfig, prevStore, store);
}
