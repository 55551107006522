import { folder } from "leva";
import { Schema } from "leva/dist/declarations/src/types";
import { handleChangeObjectType } from ".";

import {
  IcosahedronConfigGroupSchema,
  SphereConfigGroupSchema,
  SpikeConfigGroupSchema,
} from "../schema";
import { retriggerEditingSelection } from "../stores";
import {
  CapsidDef,
  ConfigGroupType,
  EnvironmentDef,
  IcosahedronDef,
  ObjectType,
  SphereDef,
  SpikeDef,
} from "../types";
import {
  ConfigDescriptor,
  generateSpikeName,
  handleChangeDef,
  handleDeleteDef,
  PropPath,
} from "./particleConfig";

const controlGroupConfigFactories = new Map<
  ConfigGroupType | ObjectType,
  (
    onChange: (path: PropPath, v: unknown) => void,
    onDelete: (path: PropPath, looseFocus: boolean) => void,
    isRoot: boolean,
    path: PropPath,
    def: any
  ) => [string, Schema]
>([
  [
    ConfigGroupType.Environment,
    (onChange, onDelete, isRoot, path, def: EnvironmentDef) => [
      "environment",
      {
        background: {
          value: def.background,
          onChange: (v: unknown) => onChange([...path, "background"], v),
        },
      },
    ],
  ],

  [
    ConfigGroupType.Capsid,
    (onChange, onDelete, isRoot, path, def: CapsidDef) => {
      const objectControlConfig = makeControlDef(
        [[...path, "object"], def.object],
        false
      );
      return [
        "capsid",
        {
          object: folder(objectControlConfig![1]),
        },
      ];
    },
  ],

  [
    ConfigGroupType.Spike,
    (onChange, onDelete, isRoot, path, def: SpikeDef) => {
      const fieldSchemas = SpikeConfigGroupSchema.properties;
      const objectControlConfig = makeControlDef(
        [[...path, "object"], def.object],
        false
      );
      return [
        generateSpikeName(def.uuid),
        {
          distance: {
            value: def.distance,
            min: fieldSchemas.distance.minimum,
            max: fieldSchemas.distance.maximum,
            step: fieldSchemas.distance.multipleOf,
            onChange: (v: unknown) => onChange([...path, "distance"], v),
          },
          object: folder(objectControlConfig![1]),
        },
      ];
    },
  ],

  [
    ObjectType.Icosahedron,
    (onChange, onDelete, isRoot, path, def: IcosahedronDef) => {
      const fieldSchemas = IcosahedronConfigGroupSchema.properties;
      return [
        def.type,
        {
          "change type": {
            options: [ObjectType.Icosahedron, ObjectType.Sphere],
            value: ObjectType.Icosahedron,
            onChange: (v: ObjectType) => {
              if (v !== def.type) {
                handleChangeObjectType(path, v);
                retriggerEditingSelection();
              }
            },
            transient: true,
          },
          complexity: {
            value: def.complexity,
            min: fieldSchemas.complexity.minimum,
            max: fieldSchemas.complexity.maximum,
            step: 1,
            onChange: (v: unknown) => onChange([...path, "complexity"], v),
          },
          size: {
            value: def.size,
            min: fieldSchemas.size.minimum,
            max: fieldSchemas.size.maximum,
            step: fieldSchemas.size.multipleOf,
            onChange: (v: unknown) => onChange([...path, "size"], v),
          },
          color: {
            value: def.color,
            onChange: (v: unknown) => onChange([...path, "color"], v),
          },
        },
      ];
    },
  ],

  [
    ObjectType.Sphere,
    (onChange, onDelete, isRoot, path, def: SphereDef) => {
      const fieldSchemas = SphereConfigGroupSchema.properties;
      return [
        def.type,
        {
          "change type": {
            options: [ObjectType.Icosahedron, ObjectType.Sphere],
            value: ObjectType.Sphere,
            onChange: async (v: ObjectType) => {
              if (v !== def.type) {
                handleChangeObjectType(path, v);
                retriggerEditingSelection();
              }
            },
            transient: true,
          },
          size: {
            value: def.size,
            min: fieldSchemas.size.minimum,
            max: fieldSchemas.size.maximum,
            step: fieldSchemas.size.multipleOf,
            onChange: (v: unknown) => onChange([...path, "size"], v),
          },
          complexityX: {
            value: def.complexityX,
            min: fieldSchemas.complexityX.minimum,
            max: fieldSchemas.complexityY.maximum,
            step: 1,
            onChange: (v: unknown) => onChange([...path, "complexityX"], v),
          },
          complexityY: {
            value: def.complexityY,
            min: fieldSchemas.complexityY.minimum,
            max: fieldSchemas.complexityY.maximum,
            step: 1,
            onChange: (v: unknown) => onChange([...path, "complexityY"], v),
          },
          color: {
            value: def.color,
            onChange: (v: unknown) => onChange([...path, "color"], v),
          },
        },
      ];
    },
  ],
]);

export const makeControlDef = (
  conf: ConfigDescriptor,
  isRoot: boolean
): [string, Schema] | undefined => {
  const [path, def] = conf;
  const factory = controlGroupConfigFactories.get(def.type);
  if (factory) {
    return factory(handleChangeDef, handleDeleteDef, isRoot, path, def);
  }
  return undefined;
};
