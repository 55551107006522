import { Instances } from "@react-three/drei";
import { ThreeEvent } from "@react-three/fiber";
import React, { forwardRef, useCallback } from "react";
import mergeRefs from "react-merge-refs";

import { useMountedNodeRef } from "../hooks";
import { useUiState } from "../stores";
import { ObjectProps, ObjectType, IcosahedronDef, SphereDef } from "../types";
import { ObjectIcosahedron } from "./ObjectIcosahedron";
import { ObjectSphere } from "./ObjectSphere";

export const ObjectContent = (props: ObjectProps) => {
  const {
    def: { type },
  } = props;

  // TODO: Fix union type discrimination
  switch (type) {
    case ObjectType.Icosahedron:
      return (
        <ObjectIcosahedron
          {...(props as unknown as ObjectProps<IcosahedronDef>)}
        />
      );

    case ObjectType.Sphere:
      return <ObjectSphere {...(props as unknown as ObjectProps<SphereDef>)} />;

    default:
      throw new Error(`Cannot find object component for type ${type}!`);
  }
};

/**
 * Wrap the actual object if it is an instanceParent
 */
export const Object = forwardRef<THREE.Mesh | THREE.InstancedMesh, ObjectProps>(
  ({ onUpdateMesh, instanceParent, configUuid, ...props }, ref) => {
    if (!props.def) {
      return null;
    }
    const [editingSelection, setEditingSelection] =
      useUiState.editingSelection();
    const [hoveringSelection, setHoveringSelection] =
      useUiState.hoveringSelection();
    const [meshRef, meshRefHandler] = useMountedNodeRef<
      THREE.InstancedMesh | THREE.Mesh | null
    >();

    const handleClick = useCallback(
      (e) => {
        e.stopPropagation();
        setEditingSelection({
          uuid: configUuid,
        });
      },
      [configUuid, meshRef]
    );
    const handleStartHovering = useCallback(
      (event: ThreeEvent<PointerEvent>) => {
        event.stopPropagation();
        setHoveringSelection({
          uuid: configUuid,
        });
      },
      [configUuid, meshRef, setHoveringSelection]
    );
    // Only unhover if this is you
    const handleStopHovering = useCallback(
      (event: ThreeEvent<PointerEvent>) => {
        event.stopPropagation();
        if (hoveringSelection && hoveringSelection.uuid === configUuid) {
          setHoveringSelection(null);
        }
      },
      [configUuid, setHoveringSelection]
    );

    const isCurrentlyBeingConfigured = editingSelection
      ? configUuid === editingSelection!.uuid
      : false;
    const isCurrentlyBeingHovered = hoveringSelection
      ? configUuid === hoveringSelection!.uuid
      : false;
    const somethingElseIsBeingConfigured = editingSelection
      ? configUuid !== editingSelection!.uuid
      : false;
    const somethingElseIsBeingHovered = hoveringSelection
      ? configUuid !== hoveringSelection!.uuid
      : false;

    const WrapperElement = instanceParent ? Instances : "mesh";

    return (
      <WrapperElement
        receiveShadow
        castShadow
        onUpdate={onUpdateMesh}
        onClick={handleClick}
        onPointerOver={handleStartHovering}
        onPointerOut={handleStopHovering}
        ref={mergeRefs([ref, meshRefHandler])}
        scale={props.scale || 1}
        name={configUuid}
      >
        <ObjectContent
          {...props}
          configUuid={props.def.uuid}
          isMuted={
            somethingElseIsBeingHovered || somethingElseIsBeingConfigured
          }
          isHighlighted={isCurrentlyBeingHovered || isCurrentlyBeingConfigured}
          isConfiguring={isCurrentlyBeingConfigured}
        />
        {props.children}
      </WrapperElement>
    );
  }
);
Object.displayName = "Object";
