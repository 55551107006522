import { useFrame, useThree } from "@react-three/fiber";
import { EffectComposer, Outline, Vignette } from "@react-three/postprocessing";
import {
  EffectComposer as TEffectComposer,
  OutlineEffect as TOutlineEffect,
} from "postprocessing";
import React, { useRef, useState } from "react";

import { getUiState } from "../stores";
import { BlendFunction } from "postprocessing";

export const Effects = ({
  contrastColor,
}: {
  contrastColor: undefined | string;
}) => {
  const outlineRef = useRef<TOutlineEffect>(null);
  const effectsRef = useRef<TEffectComposer>(null);
  const [autoClear, setAutoClear] = useState(false);

  const { gl, scene } = useThree();
  useFrame(() => {
    if (!outlineRef.current || !effectsRef.current) {
      return;
    }
    const [hoveringSelection] = getUiState.hoveringSelection();
    const [editingSelection] = getUiState.editingSelection();

    if (editingSelection && editingSelection.uuid) {
      setAutoClear(false);
      const element = scene.getObjectByName(editingSelection.uuid);
      if (element) {
        outlineRef.current.selection.set([element]);
      }
    } else if (hoveringSelection && hoveringSelection.uuid) {
      setAutoClear(false);
      const element = scene.getObjectByName(hoveringSelection.uuid);
      if (element) {
        outlineRef.current.selection.set([element]);
      }
    } else {
      if (outlineRef.current.selection) {
        outlineRef.current.selection.clear();
        outlineRef.current.update(gl);
      }
    }
  });

  return (
    <EffectComposer autoClear={autoClear} ref={effectsRef}>
      <Vignette eskil={true} offset={0.2} darkness={40} opacity={0.3} />

      <Outline
        edgeStrength={5}
        visibleEdgeColor={(contrastColor as unknown as number) || 0xffffff}
        hiddenEdgeColor={
          ((contrastColor + "ee") as unknown as number) || 0xffffffee
        }
        xRay={true}
        blur={false}
        ref={outlineRef}
        blendFunction={BlendFunction.ALPHA}
      />
    </EffectComposer>
  );
};
