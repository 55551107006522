import { css } from "@emotion/css";
import { OrbitControls, Stats } from "@react-three/drei";
import { Canvas as RCanvas } from "@react-three/fiber";
import React, { useCallback } from "react";
import { useCssVars } from "../hooks";

import { useParticleConfig, useUiState } from "../stores";
import { Effects } from "./Effects";
import { Particle } from "./Particle";

type CanvasProps = { className: string };

export const Canvas = ({ className }: CanvasProps) => {
  const [env] = useParticleConfig.environment();
  const [contrastColor] = useCssVars(env && env.background);
  const [, setEditingSelection] = useUiState.editingSelection();
  const handleClickBackground = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setEditingSelection(null);
    },
    [setEditingSelection]
  );

  return (
    <RCanvas
      shadows
      id="Canvas"
      className={className}
      camera={{ position: [0, 0, 6], fov: 90 }}
      mode="concurrent"
      onPointerMissed={handleClickBackground}
      linear
      flat
    >
      <OrbitControls
        makeDefault
        enableZoom={true}
        enablePan={false}
        enableRotate={true}
      />

      <ambientLight intensity={0.5} />
      <directionalLight
        position={[1, 2, -1]}
        intensity={2}
        shadow-mapSize-height={1024}
        shadow-mapSize-width={1024}
        shadow-camera-far={25}
        shadow-camera-near={-5}
        color={contrastColor}
      />
      <directionalLight
        position={[3, 0, 3]}
        intensity={1}
        castShadow
        shadow-mapSize-height={1024}
        shadow-mapSize-width={1024}
        shadow-camera-far={25}
        shadow-camera-near={-5}
      />
      <Particle />

      {env && env.background && (
        <color args={[env.background]} attach="background" />
      )}

      <Effects contrastColor={contrastColor} />
      {import.meta.env.DEV && (
        <Stats
          className={css`
            top: auto !important;
            bottom: 0 !important;
            left: 0 !important;
          `}
        />
      )}
    </RCanvas>
  );
};
