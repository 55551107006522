import { css } from "@emotion/css";
import React, { useMemo } from "react";
import {
  handleEditingSelectionPathUpdate,
  handleEditingSelectionUuidUpdate,
  handleHoveringSelectionPathUpdate,
  handleHoveringSelectionUuidUpdate,
  useParticleConfig,
  useUiState,
} from "../stores";
import { Box, Circle, Delete, PlusCircle, Shuffle, Sun } from "react-feather";
import {
  generateSpikeName,
  handleCreateNewSpike,
  handleDeleteDef,
  handleRandomizeParticleConfig,
} from "../utils";
import classNames from "classnames";

const buttonStyle = css`
  color: var(--contrast-color);
  appearance: none;
  border: 0;
  padding: 1em 2em 1em 1.8em;
  display: flex;
  width: 100%;
  align-items: center;
  background: transparent;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.2em;
    cursor: pointer;
  }
`;

const MenuItem = ({
  onClick,
  label,
  icon,
  isActive,
  children,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onHover = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onHoverOut = () => {},
}: {
  onClick: () => void;
  onHover?: () => void;
  onHoverOut?: () => void;
  label: string;
  icon: React.ReactElement;
  isActive?: boolean;
  children?: React.ReactChild;
}) => (
  <li
    className={css`
      display: flex;
      flex-direction: row;
      .actions {
        opacity: 0;
        transition: opacity 50ms linear;
      }
      &:hover .actions {
        opacity: 1;
      }
    `}
  >
    <button
      onClick={onClick}
      onPointerOver={onHover}
      onPointerOut={onHoverOut}
      className={css`
        ${buttonStyle}

        ${isActive &&
        `
            outline: 2px  solid var(--contrast-color);
            outline-offset: -0.33em;
          `}
      `}
    >
      {icon}
      <span
        className={css`
          margin-left: 0.5em;
        `}
      >
        {label}
      </span>
    </button>
    <ul
      className={classNames(
        "actions",
        css`
          list-style-type: none;
          padding: 0;
          margin: 0;
        `
      )}
    >
      {children}
    </ul>
  </li>
);

const makeHandleSpikeConfig = (uuid: string) => () =>
  handleEditingSelectionUuidUpdate(uuid);
const makeHandleSpikeConfigHover = (uuid: string) => () =>
  handleHoveringSelectionUuidUpdate(uuid);
const makeDeleteSpikeConfig = (index: number) => () =>
  handleDeleteDef(["spikes", index], true);

const handleEnvironmentConfig = () =>
  handleEditingSelectionPathUpdate("environment");
const handleEnvironmentConfigHover = () =>
  handleHoveringSelectionPathUpdate("environment");

const handleCapsidConfig = () => handleEditingSelectionPathUpdate("capsid");
const handleCapsidConfigHover = () =>
  handleHoveringSelectionPathUpdate("capsid");

//const makeHandleHoverOut = (uuid: string, path?: string) => {
//  const [hoveringSelection,setHover] =
//}

export const Menu = () => {
  const [editingSelection] = useUiState.editingSelection();

  const [spikesDef] = useParticleConfig.spikes();
  const spikesDisplayConfig = useMemo(
    () => (spikesDef ? spikesDef.sort((a, b) => a.weight - b.weight) : []),
    [spikesDef]
  );

  return (
    <nav
      className={css`
        position: fixed;
        bottom: 0;
        right: 0.75rem;
        z-index: 1;
        width: 40ch;
        user-select: none;
        &:hover:after {
          opacity: 1;
        }
        &:after {
          transition: opacity 100ms ease-in-out;
          opacity: 0;
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      `}
    >
      <ul
        className={css`
          list-style-type: none;
          padding: 0;
          margin: 0 0 1em 0;
          .spikesActions {
            opacity: 0;
            transition: opacity 50ms linear;
          }
          &:hover .spikesActions {
            opacity: 1;
          }
        `}
      >
        <li
          aria-label="Spikes"
          className={css`
            margin-bottom: 1em;
          `}
        >
          <div
            className={css`
              display: flex:
              flex-direction: column;
            `}
          >
            <button
              className={classNames(
                "spikesActions",
                css`
                  ${buttonStyle}
                  display: inline-flex;
                  flex-direction: row;
                `
              )}
              onClick={
                handleCreateNewSpike as unknown as React.MouseEventHandler<HTMLButtonElement>
              }
            >
              <PlusCircle />
              <span
                className={css`
                  margin-left: 0.5em;
                `}
              >
                add spike
              </span>
            </button>
          </div>
          <ul
            className={css`
              list-style-type: none;
              padding: 0;
            `}
          >
            {spikesDisplayConfig.map((spike, spikeIndex) => (
              <MenuItem
                key={spike.uuid}
                onClick={makeHandleSpikeConfig(spike.uuid)}
                onHover={makeHandleSpikeConfigHover(spike.uuid)}
                label={generateSpikeName(spike.uuid)}
                icon={<Sun />}
                isActive={
                  editingSelection
                    ? editingSelection.uuid === spike.uuid
                    : false
                }
              >
                <li>
                  <button
                    onClick={makeDeleteSpikeConfig(spikeIndex)}
                    className={css`
                      ${buttonStyle}
                      padding: 1em 2em 1em 1.8em;
                    `}
                    title={`delete spike ${spikeIndex + 1}`}
                  >
                    <Delete />
                  </button>
                </li>
              </MenuItem>
            ))}
          </ul>
        </li>
        <MenuItem
          onClick={handleCapsidConfig}
          onHover={handleCapsidConfigHover}
          label="capsid"
          icon={<Circle />}
          isActive={
            editingSelection ? editingSelection.path === "capsid" : false
          }
        />
        <MenuItem
          onClick={handleEnvironmentConfig}
          onHover={handleEnvironmentConfigHover}
          label="environment"
          icon={<Box />}
          isActive={
            editingSelection ? editingSelection.path === "environment" : false
          }
        />
        <MenuItem
          onClick={handleRandomizeParticleConfig}
          label="randomize"
          icon={<Shuffle />}
        ></MenuItem>
      </ul>
    </nav>
  );
};
