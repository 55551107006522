import React from "react";
import ReactDOM from "react-dom";
import { App } from "./components-dom/App";
import "normalize.css";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { registerSW } from "virtual:pwa-register";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateSW = registerSW({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onNeedRefresh() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOfflineReady() {},
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
