import createStore from "teaful";
import { RenderingOptionsState } from "../types";

const initialState: RenderingOptionsState = {
  wireframe: false,
  shadows: true,
  postprocessing: true,
};
export const { useStore: useRenderingOptions, getStore: getRenderingOptions } =
  createStore(initialState);
