import { Instance } from "@react-three/drei";
import { Position } from "@react-three/drei/helpers/Position";
import React, { forwardRef, useRef } from "react";

import { useMountedNodeRef } from "../hooks";
import { getParticleConfig, useParticleConfig } from "../stores";
import { SpikeDef } from "../types";
import { Object } from "./Object";
import { useFrame } from "@react-three/fiber";
import mergeRefs from "react-merge-refs";
import { Vector3 } from "three";

const ParticleSpikeFragment_: React.ForwardRefRenderFunction<
  unknown,
  {
    def: SpikeDef;
    x: number;
    y: number;
    z: number;
    scaleRef: React.MutableRefObject<number>;
  }
> = ({ def, x, y, z, scaleRef }, ref) => {
  const positionRef = useRef<THREE.Vector3>(
    new Vector3(x, y, z).multiplyScalar(def.distance)
  );
  useFrame(() => {
    if (instanceRef.current) {
      instanceRef.current.position
        .set(x, y, z)
        .multiplyScalar(def.distance / scaleRef.current);
    }
  });
  const instanceRef = useRef<Position>();

  return (
    <Instance
      position={positionRef.current}
      ref={mergeRefs([instanceRef, ref])}
    />
  );
};
ParticleSpikeFragment_.displayName = "ParticleSpikeFragment";
const ParticleSpikeFragment = forwardRef(ParticleSpikeFragment_);

const ParticleSpike = ({
  vertices,
  def,
}: {
  vertices: null | THREE.BufferAttribute | THREE.InterleavedBufferAttribute;
  name?: number | string;
  index: number;
  def: SpikeDef;
}) => {
  const [meshRef, meshRefHandler] =
    useMountedNodeRef<
      THREE.InstancedMesh<
        THREE.BufferGeometry,
        THREE.Material | THREE.Material[]
      >
    >();
  const scaleRef = useRef<number>(1);
  useFrame(() => {
    const [capsid] = getParticleConfig.capsid();
    if (capsid && capsid.object.size && meshRef) {
      scaleRef.current = capsid.object.size;
      meshRef.scale.setScalar(1);
    }
  });
  return (
    <Object
      ref={meshRefHandler as any}
      instanceParent
      configUuid={def.uuid}
      def={def.object}
    >
      {vertices &&
        [...Array(vertices.count)].map((_, j) => (
          <ParticleSpikeFragment
            key={j}
            x={vertices.getX(j)}
            y={vertices.getY(j)}
            z={vertices.getZ(j)}
            def={def}
            scaleRef={scaleRef}
          />
        ))}
    </Object>
  );
};

export const ParticleSpikes = ({
  vertices,
}: {
  vertices: null | THREE.BufferAttribute | THREE.InterleavedBufferAttribute;
}) => {
  const [spikeDefs] = useParticleConfig.spikes();
  if (!spikeDefs) {
    return null;
  }
  return (
    <>
      {spikeDefs &&
        spikeDefs.map((def, i) => (
          <ParticleSpike index={i} vertices={vertices} key={i} def={def} />
        ))}
    </>
  );
};
