import React, { memo, useMemo } from "react";

import { useRenderingOptions } from "../stores";
import { SphereDef, ObjectProps } from "../types";

const ObjectSphere_ = ({ def, onUpdateGeo }: ObjectProps<SphereDef>) => {
  const [wireframe] = useRenderingOptions.wireframe();
  const geoArgs = useMemo<[number, number, number]>(
    () => [def.size, def.complexityX, def.complexityY],
    [def.size, def.complexityX, def.complexityY]
  );
  return (
    <>
      <sphereGeometry args={geoArgs} onUpdate={onUpdateGeo} />
      <meshPhysicalMaterial color={def.color} wireframe={wireframe} />
    </>
  );
};

export const ObjectSphere = memo(ObjectSphere_);
